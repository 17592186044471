import axios from "@/config/http";
// import qs from 'qs'
export const appCheckApi = {
	// 通过应用审核请求
	Pass(params) {
		return axios.post("/cloud-platform-backend-facade/front-api/v1/audit-platform/app/audit/pass", params )
	},
	// 驳回应用审核请求
	Reject(params) {
		return axios.post("/cloud-platform-backend-facade/front-api/v1/audit-platform/app/audit/reject", params )
	},
	// 下架应用
	Remove(params) {
		return axios.post("/cloud-platform-backend-facade/front-api/v1/audit-platform/app/audit/remove", params )
	},
	// 审核应用信息分页列表
	GetAppList(params) {
		return axios.get("/cloud-platform-backend-facade/front-api/v1/audit-platform/app/page", { params })
	},
	// 查询应用审核信息详情
	GetAppDetail(params) {
		return axios.get("/cloud-platform-backend-facade/front-api/v1/audit-platform/app/detail", { params })
	},
	// 校验下架当前应用会影响的解决方案信息
	Verify(params) {
		return axios.get("/cloud-platform-backend-facade/front-api/v1/audit-platform/app/audit/remove/verify", { params })
	},
}
